import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core";

import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Autocomplete, {
	createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";

import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import ButtonWithSpinner from "../ButtonWithSpinner";
import toastError from "../../errors/toastError";
import useQueues from "../../hooks/useQueues";
import useUsers from "../../hooks/useUsers";
import useWhatsApps from "../../hooks/useWhatsApps";
import { AuthContext } from "../../context/Auth/AuthContext";
import { Can } from "../Can";

const useStyles = makeStyles((theme) => ({
	maxWidth: {
		width: "100%",
	},
}));

const filterOptions = createFilterOptions({
	trim: true,
});

const TransferTicketModal = ({ modalOpen, onClose, ticketid, ticketWhatsappId, ticket }) => {
	const history = useHistory();
	const [options, setOptions] = useState([]);
	const [queues, setQueues] = useState([]);
	const [users, setUsers] = useState([]);
	const [allQueues, setAllQueues] = useState([]);
	const [allUsers, setAllUsers] = useState([]);
	const [loading, setLoading] = useState(false);
	const [searchParam, setSearchParam] = useState("");
	const [selectedUser, setSelectedUser] = useState('');
	const [selectedQueue, setSelectedQueue] = useState('');
	const [selectedWhatsapp, setSelectedWhatsapp] = useState(ticketWhatsappId);
	const classes = useStyles();
	const { findAll: findAllQueues } = useQueues();
	const { findAll: findAllUsers } = useUsers();
	const { loadingWhatsapps, whatsApps } = useWhatsApps();

	const { user: loggedInUser } = useContext(AuthContext);

	useEffect(() => {
		const loadQueues = async () => {
			const list = await findAllQueues();
			const filteredList = list.filter(item => item?.initialLevel === ticket?.queue?.initialLevel || !item?.initialLevel && item?.isChatbot === false);
			const uniqueQueues = filteredList?.reduce((uniqueQueues, queue) => {
				const existingQueue = uniqueQueues?.find(item => item.name === queue.name);
				if (!existingQueue) {
					uniqueQueues.push({ id: queue.id, name: queue.name });
				}
				return uniqueQueues;
			}, []);
			uniqueQueues.sort((a, b) => a.name.localeCompare(b.name));
			setAllQueues(uniqueQueues);
			setQueues(uniqueQueues);
		}
		const loadUsers = async () => {
			const list = await findAllUsers();
			setAllUsers(list.users);
			setUsers(list.users);
		}
		loadQueues();
		loadUsers();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleClose = () => {
		onClose();

	};
	const handleSelectedUser = (e) => {
		const userId = e.target.value;
		setSelectedUser(userId)
		const userQueues = users.find(item => item.id === userId)?.queues || [];

		const uniqueQueues = userQueues.reduce((uniqueQueues, queue) => {
			const existingQueue = uniqueQueues.find(item => item.name === queue.name);
			if (!existingQueue) {
				uniqueQueues.push({ id: queue.id, name: queue.name });
			}
			return uniqueQueues;
		}, []);
		uniqueQueues.sort((a, b) => a.name.localeCompare(b.name));
		setQueues(uniqueQueues.length > 0 ? uniqueQueues : allQueues)

	};
	const handleSaveTicket = async e => {
		e.preventDefault();
		if (!ticketid) return;
		setLoading(true);
		try {
			let data = {};
			data.wasTransferedQueue = 1;

			if (selectedUser) {
				const selectedUserData = users.find(item => item.id === selectedUser);
				const userHasQueue = selectedUserData.queues.find(item => item.id === selectedQueue);
				if (!userHasQueue) {
					setLoading(false);
					return toastError({ response: { data: { error: 'Usuário não possui esse setor!' } } });
				}
				data.userId = selectedUser
			}

			if (selectedQueue && selectedQueue !== null) {
				data.queueId = selectedQueue

				if (!selectedUser) {
					data.status = 'pending';
					data.userId = null;
					data.transf = true;
				}
			}

			if (selectedWhatsapp) {
				data.whatsappId = selectedWhatsapp;
			}

			await api.put(`/tickets/${ticketid}`, data);

		
			history.push(`/tickets`);
		} catch (err) {
			toastError(err);
		}
	};

	return (
		<Dialog open={modalOpen} onClose={handleClose} maxWidth="lg" scroll="paper">
			<form onSubmit={handleSaveTicket}>
				<DialogTitle id="form-dialog-title" style={{ width: 500 }}>
					{i18n.t("transferTicketModal.title")}
				</DialogTitle>
				<DialogContent dividers>
					{/* <Autocomplete
						style={{ width: 300, marginBottom: 20 }}
						getOptionLabel={option => `${option.name}`}
						onChange={(e, newValue) => {
							setSelectedUser(newValue);
							if (newValue != null && Array.isArray(newValue.queues)) {
								setQueues(newValue.queues);
							} else {
								setQueues(allQueues);
								setSelectedQueue('');
							}
						}}
						options={options}
						filterOptions={filterOptions}
						freeSolo
						autoHighlight
						noOptionsText={i18n.t("transferTicketModal.noOptions")}
						loading={loading}
						renderInput={params => (
							<TextField
								{...params}
								label={i18n.t("transferTicketModal.fieldLabel")}
								variant="outlined"
								required
								autoFocus
								onChange={e => setSearchParam(e.target.value)}
								InputProps={{
									...params.InputProps,
									endAdornment: (
										<React.Fragment>
											{loading ? (
												<CircularProgress color="inherit" size={20} />
											) : null}
											{params.InputProps.endAdornment}
										</React.Fragment>
									),
								}}
							/>
						)}
					/> */}
					<FormControl variant="outlined" className={classes.maxWidth} >
						<InputLabel>{i18n.t("transferTicketModal.fieldLabel")}</InputLabel>
						<Select

							value={selectedUser}
							// onChange={(e) => setSelectedUser(e.target.value)}
							onChange={handleSelectedUser}
							label={i18n.t("transferTicketModal.fieldLabel")}
						>
							<MenuItem value={''}>&nbsp;</MenuItem>
							{users.map((user) => (
								<MenuItem key={user?.id} value={user?.id}>{user?.name}</MenuItem>
							))}
						</Select>
					</FormControl>
					<FormControl variant="outlined" className={classes.maxWidth} style={{ marginTop: 20 }}>
						<InputLabel>{i18n.t("transferTicketModal.fieldQueueLabel")}</InputLabel>
						<Select
							required
							value={selectedQueue}
							onChange={(e) => setSelectedQueue(e.target.value)}
							label={i18n.t("transferTicketModal.fieldQueuePlaceholder")}
						>
							<MenuItem value={''}>&nbsp;</MenuItem>
							{queues.map((queue) => (
								<MenuItem key={queue.id} value={queue.id}>{queue.name}</MenuItem>
							))}
						</Select>
					</FormControl>
					<Can
						/*role={loggedInUser.profile}
						perform="ticket-options:transferWhatsapp"
						yes={() => (!loadingWhatsapps &&
							<FormControl variant="outlined" className={classes.maxWidth} style={{ marginTop: 20 }}>
								<InputLabel>{i18n.t("transferTicketModal.fieldConnectionLabel")}</InputLabel>
								<Select
									value={selectedWhatsapp}
									onChange={(e) => setSelectedWhatsapp(e.target.value)}
									label={i18n.t("transferTicketModal.fieldConnectionPlaceholder")}
								>
									{whatsApps.map((whasapp) => (
										<MenuItem key={whasapp.id} value={whasapp.id}>{whasapp.name}</MenuItem>
									))}
								</Select>
							</FormControl>
						)}
					*//>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={handleClose}
						color="secondary"
						disabled={loading}
						variant="outlined"
					>
						{i18n.t("transferTicketModal.buttons.cancel")}
					</Button>
					<ButtonWithSpinner
						variant="contained"
						type="submit"
						color="primary"
						loading={loading}
					>
						{i18n.t("transferTicketModal.buttons.ok")}
					</ButtonWithSpinner>
				</DialogActions>
			</form>
		</Dialog>
	);
};

export default TransferTicketModal;