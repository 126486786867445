import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import api from "../../services/api";
import TextField from "@material-ui/core/TextField";
import toastError from "../../errors/toastError";
import CircularProgress from "@material-ui/core/CircularProgress";
import Autocomplete, { createFilterOptions } from "@material-ui/lab/Autocomplete";

const FowardModal = ({ title, children, open, onClose, onConfirm, message }) => {
  const [searchParam, setSearchParam] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedContact, setSelectedContact] = useState(null);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (open) {
      fetchContacts(""); // Carrega os contatos iniciais ao abrir o modal
    }
  }, [open]);

  useEffect(() => {
    if (searchParam.length >= 2) {
      const delayDebounceFn = setTimeout(() => {
        fetchContacts(searchParam);
      }, 500);

      return () => clearTimeout(delayDebounceFn);
    }
  }, [searchParam]);

  const fetchContacts = async (search) => {
    setLoading(true);
    try {
      const { data } = await api.get("contacts", { params: { searchParam: search } });
      setOptions(data.contacts);
    } catch (err) {
      toastError(err);
    } finally {
      setLoading(false);
    }
  };

  const handleConfirm = () => {
    onClose(false);
    onConfirm(selectedContact, message);
  };

  return (
    <Dialog open={open} onClose={() => onClose(false)} aria-labelledby="confirm-dialog">
      <DialogTitle id="confirm-dialog">{title}</DialogTitle>
      <DialogContent dividers>
        <Typography>{children}</Typography>
        <Autocomplete
          options={options}
          loading={loading}
          style={{ width: 300 }}
          clearOnBlur
          autoHighlight
          freeSolo
          clearOnEscape
          getOptionLabel={(option) => option.number ? `${option.name} - ${option.number}` : option.name}
          onChange={(e, newValue) => setSelectedContact(newValue)}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Buscar contato"
              variant="outlined"
              autoFocus
              required
              onChange={(e) => setSearchParam(e.target.value)}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {loading && <CircularProgress color="inherit" size={20} />}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={() => onClose(false)} color="default">
          Cancelar
        </Button>
        <Button variant="contained" onClick={handleConfirm} color="secondary">
          Confirmar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FowardModal;
