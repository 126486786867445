import React, { useState, useEffect } from "react";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { Checkbox, ListItemText } from "@material-ui/core";
import { i18n } from "../../translate/i18n";

const TicketsQueueSelect = ({
  userQueues,
  selectedQueueIds = [],
  onChange,
  mergeQueues
}) => {
 const [formatedQueues, setFormatedQueues] = useState([]);
  const handleChange = e => {
    onChange(e.target.value);
  };

  const handleCheckboxChange = (e, queueName) => {
    const { checked } = e.target;
    let newSelectedQueueIds = [...selectedQueueIds];
    const queuesWithSameName = userQueues.filter(queue => queue.name === queueName);

    if (checked) {

      queuesWithSameName.forEach(queue => {
        if (!newSelectedQueueIds.includes(queue.id)) {
          newSelectedQueueIds.push(queue.id);
        }
      });
    } else {
      newSelectedQueueIds = newSelectedQueueIds.filter(id => !queuesWithSameName.map(queue => queue.id).includes(id));
    }
    onChange(newSelectedQueueIds);
  };
  useEffect(() => {
  formatQueues()
  }, [userQueues]); 
  const formatQueues = () => {
    const encounteredNames = new Set();
    const queuesWithUniqueNames = userQueues.filter(queue => {
      if (!encounteredNames.has(queue.name)) {
        encounteredNames.add(queue.name);
        return true;
      }  
      return false;
    });
    
  setFormatedQueues(queuesWithUniqueNames)
  }

  return (
    <div style={{ width: 120, marginTop: -4 }}>
      {!mergeQueues ? (
        <FormControl fullWidth margin="dense">
        <Select
          multiple
          displayEmpty
          variant="outlined"
          value={selectedQueueIds}
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left",
            },
            getContentAnchorEl: null,
          }}
          renderValue={() => i18n.t("ticketsQueueSelect.placeholder")}
        >
          {formatedQueues?.length > 0 &&
            formatedQueues.map(queue => (
              <MenuItem dense key={queue.id} value={queue.id}>
                <Checkbox
                  style={{
                    color: queue.color,
                  }}
                  size="small"
                  color="primary"
                  checked={selectedQueueIds.includes(queue.id)}
                  onChange={(e) => handleCheckboxChange(e, queue.name)}
                />
                <ListItemText primary={queue.name} />
              </MenuItem>
            ))}
        </Select>
      </FormControl>
      ) : (
        <FormControl fullWidth margin="dense">
        <Select
          multiple
          displayEmpty
          variant="outlined"
          value={selectedQueueIds}
          // onChange={handleChange}
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left",
            },
            getContentAnchorEl: null,
          }}
          renderValue={() => i18n.t("ticketsQueueSelect.placeholder")}
        >
          {userQueues?.length > 0 &&
            userQueues.reduce((uniqueQueues, queue) => {
              const existingQueue = uniqueQueues.find(item => item.name === queue.name);
              if (!existingQueue) {
                uniqueQueues.push({ id: queue.id, name: queue.name });
              }
              return uniqueQueues;
            }, []).map(queue => (
              <MenuItem dense key={queue.id} value={queue.id}>
                <Checkbox
                  style={{
                    color: userQueues.find(q => q.id === queue.id).color,
                  }}
                  size="small"
                  color="primary"
                  checked={selectedQueueIds.includes(queue.id)}
                  onChange={(e) => handleCheckboxChange(e, queue.name)}
                />
                <ListItemText primary={queue.name} onClick={(e) => handleCheckboxChange(e, queue.name)} />
              </MenuItem>
            ))}
        </Select>
      </FormControl>
      )}
    </div>
  );
};

export default TicketsQueueSelect;
