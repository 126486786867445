import React, { useState, useContext } from "react";
import MenuItem from "@material-ui/core/MenuItem";
import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import ConfirmationModal from "../ConfirmationModal";
import FowardModal from "../FowardMessageModal";
import { Menu } from "@material-ui/core";
import { ReplyMessageContext } from "../../context/ReplyingMessage/ReplyingMessageContext";
import toastError from "../../errors/toastError";
import { toast } from "react-toastify";

const MessageOptionsMenu = ({ message, menuOpen, handleClose, anchorEl }) => {
  const { setReplyingMessage } = useContext(ReplyMessageContext);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [forwardModalOpen, setForwardModalOpen] = useState(false);
  const [selectedContact, setSelectedContact] = useState(null);
  const [medias, setMedias] = useState([]);

  const handleDeleteMessage = async () => {
    try {
      await api.delete(`/messages/${message.id}`);
    } catch (err) {
      toastError(err);
    }
  };
  const handleReplyMessage = () => {
    setReplyingMessage(message);
    handleClose();
  };

  const handleFoward = async (contact, messageToForward) => {
    try {
      console.log("Encaminhando mensagem:", messageToForward);
      console.log("Para o contato:", contact);
  
      const messageWithContactNumber = {
        ...messageToForward,
        contactNumber: contact.number,
        contactName: contact.name
      };
  
      // Faz a requisição para o backend
      const response = await api.post(`/foward/${messageToForward.ticket.id}`, messageWithContactNumber);
  
      // Verifica se a requisição foi bem-sucedida (pode adaptar para o status de resposta do backend)
      if (response.status === 200) {
        toast.success(i18n.t("userModal.successFoward"));
      } else {
        toast.error(i18n.t("userModal.error"));
      }
    } catch (err) {
      console.error(err);
    }
  };
  const handleOpenConfirmationModal = (e) => {
    setConfirmationOpen(true);
    handleClose();
  };
  

  return (
    <>
      <ConfirmationModal
        title={i18n.t("messageOptionsMenu.confirmationModal.title")}
        open={confirmationOpen}
        onClose={setConfirmationOpen}
        onConfirm={handleDeleteMessage}
      >
        {i18n.t("messageOptionsMenu.confirmationModal.message")}
      </ConfirmationModal>

      <FowardModal
        title={i18n.t("messageOptionsMenu.fowardModal.title")}
        open={forwardModalOpen}
        onClose={setForwardModalOpen}
        onConfirm={handleFoward}
        message={message} // Passa a message para o modal
      >
        {i18n.t("messageOptionsMenu.fowardModal.message")}
      </FowardModal>

      <Menu
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={menuOpen}
        onClose={handleClose}
      >
        {message.fromMe && (
          <MenuItem onClick={handleOpenConfirmationModal}>
            {i18n.t("messageOptionsMenu.delete")}
          </MenuItem>
        )}
        <MenuItem onClick={handleReplyMessage}>
          {i18n.t("messageOptionsMenu.reply")}
        </MenuItem>
        <MenuItem onClick={() => setForwardModalOpen(true)}>
          {i18n.t("messageOptionsMenu.foward")}
        </MenuItem>
      </Menu>
    </>
  );
};


export default MessageOptionsMenu;
